import styled from 'styled-components';

const Title = styled.div`
width:auto;
height:30px;
display:flex;
justify-content:flex-start;
align-items:center;
padding-left: 10px;
margin: 20px 10px;
border-left: 2px solid ${({theme}) => theme.primary};
font-size: ${({theme}) => theme.font.size.xs};
  
${({theme}) => theme.media.desktop}
{
  font-size: ${({theme}) => theme.font.size.xs};
display: flex;
justify-content: flex-start;
align-items: center;
width:50%;
height:35px;
margin-top: 50px;
  margin-bottom: 0;
padding-left: 10px;
border-left: 4px solid ${({theme}) => theme.honey};
}`;
export default Title;



