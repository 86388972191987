import styled from 'styled-components';


const Button = styled.button`
width: ${({ownWidth}) => (ownWidth ? `${ownWidth}px` : `auto`)};
height: 40px;
margin:0 0 15px 15px;
  padding: 0 10px;
background-color: transparent;
border: 2px ${({theme}) => theme.primary} solid;
border-radius: 5px;
border-bottom: 3px ${({theme}) => theme.primary} solid;
border-right: 3px ${({theme}) => theme.primary} solid;
font-family: ${({theme}) => theme.font.family.Montserrat};
font-size: ${({theme}) => theme.font.size.xs};
font-weight: 600;
bottom: 10px;
transition: all 0.2s 0.3s ease-in-out;


    &:hover
  {
    transform: scale(1.07);
    text-decoration: none;
    background-color: ${({theme}) => theme.primary};
    border: 2px solid black;

  }

&:active{
  transform: scale(0.94);
}`
export default Button;


