import React from 'react';
import Offert from 'components/Ofert/Offer';
/*import News from 'components/News/News';*/
import Header from 'components/Header/Header';


const IndexPage = () => {

    return (
        <>
            <Header/>


            <Offert/>
           {/* <News/>*/}
        </>
    )
};


export default IndexPage;

