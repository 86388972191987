import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Title from 'components/Elemnts/Title';
import Button from 'components/Elemnts/Button';

const Titles = styled(Title)`
  font-size: ${({ theme }) => theme.font.size.l};
`;

const StyleWraper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  ${({ theme }) => theme.media.desktop} {
    width: 100%;
    height: 610px;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

const Product = styled.div`
  width: 80%;
  max-width: 1500px;
  height: auto;
  position: relative;


  ${({ theme }) => theme.media.desktop} {
    width: 80%;
    height: 500px;
    display: flex;
    flex-direction: row;
  }
  ${({ theme }) => theme.media.large} {
    width: 90%;
    max-width: 1500px;
    height: auto;
    display: flex;
    gap:10px;
    flex-direction: row;
    margin-top: 10px;
  }
`;

const StyledProduct = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin: 35px 0;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
  border-radius: 2%;
  padding-top: 20px;



  ${({ theme }) => theme.media.desktop} {
    display: flex;
    height: auto;
    max-height: 420px;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0 1vw;
  }
  ${({ theme }) => theme.media.large} {
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
   
  }
`;

const StyledImages = styled.div`
  width: 100%;
  height: 170px !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;

`;

const IMG = styled.img`
  width: auto;
  height: 150px;

  ${({ theme }) => theme.media.desktop} {
    width: auto;
    height: 100%;
    margin-top:15px ;
    max-height: 140px;
    min-height: 110px;
  }
`;

const StyledP = styled.div`
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 5px;
  line-height: 1.5;
  padding: 0 10px;
  ${({ theme }) => theme.media.desktop} {
    height: 50px;
    font-family: ${({ theme }) => theme.font.family.Open};
    font-size: ${({ theme }) => theme.font.size.xs};
    margin-left: 10px;
    line-height: 1.3;
  }
`;

const OfferTitle = styled.div`
  width: 95%;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  margin: 20px 0 0 10px;
  border-left: 2px solid ${({ theme }) => theme.primary};
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  font-size: ${({ theme }) => theme.font.size.s};
  ${({ theme }) => theme.media.desktop} {
    display: flex;
    width: 100%;
    height: 30px;
    margin-top: 30px;
    margin-left: 15px;
    padding-left: 7px;
    justify-content: flex-start;
    border-left: 3px solid ${({ theme }) => theme.primary};
    align-items: center;
    font-family: ${({ theme }) => theme.font.family.Montserrat};
    font-size: ${({ theme }) => theme.font.size.xs};
  }
  ${({ theme }) => theme.media.large} {
    margin-left: 10px;
    margin-top: 25px;
  }
`;

const StyleTitle = styled.div`
  width: 85%;
  height: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: 600;
  padding: 7px;

  ${({ theme }) => theme.media.desktop} {
    width: 82%;
    max-width: 1500px;
    text-align: center;
    padding: 12px;
    margin-bottom: 20px;
    position: relative;
  }
`;
const Links = styled(Link)`
  font-size: ${({ theme }) => theme.font.size.xs};
  text-decoration: none;
  color: ${({ theme }) => theme.black};

  &:hover {
    text-decoration: none;
    transition: ease-in 0.3s;
  }
  ${({ theme }) => theme.media.desktop} {
    font-size: ${({ theme }) => theme.font.size.xs};
  }
`;

const StyledButton = styled(Button)`
  border: 2px solid ${({ theme }) => theme.primary};
  margin: 10px;
`;

// const Div = styled.div`
// position: absolute;
// top: 100px;
// width: 500px;
// height: 300px;
// border: 2px red solid;
// z-index: 99999;
// `

const Offer = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsOfferShort(sort: {fields: sort}) {
        nodes {
          id
          title
          description
          button
          buttonlink
          fotoOffer {
            url
          }
           
        }
      }
    }
  `);

  return (
    <StyleWraper>
      <StyleTitle>
        <Titles>Nasza Oferta</Titles>
      </StyleTitle>
      <Product>
        {data.allDatoCmsOfferShort.nodes.map(allDatoCmsOfferShort => (
          <StyledProduct key={allDatoCmsOfferShort.id} id={allDatoCmsOfferShort.title}>
            <StyledImages>
              <IMG src={allDatoCmsOfferShort.fotoOffer.url} loading="lazy" alt="foto" />
            </StyledImages>
            <OfferTitle>
              <h2>{allDatoCmsOfferShort.title}</h2>
            </OfferTitle>
            <StyledP>{allDatoCmsOfferShort.description}</StyledP>
            <StyledButton>
              <Links to={allDatoCmsOfferShort.buttonlink}>{allDatoCmsOfferShort.button}</Links>
            </StyledButton>
          </StyledProduct>
        ))}
      </Product>
    </StyleWraper>
  );
};

export default Offer;
